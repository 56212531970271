import React, { useState, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import TopBar from "./components/TopBar";
import styled from "styled-components";
import { ScrollToTop } from "./ScrollToTop";
import style2 from "./style2.module.css";

export default function Blog() {
  const [blog, setBlog] = useState(null);

  const query = `
  {
    blogCollection {
      items {
        title
        picture {
          url
        }
        fullText
        references
      }
    }
  }
  `;

  useEffect(() => {
    ScrollToTop();
    window
      .fetch(`https://graphql.contentful.com/content/v1/spaces/2cdbwy7kruzy/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Authenticate the request
          Authorization: "Bearer jN1DBQm6NMk8BP8_kGEaRQxh_yzh6zunBhmxBBuE8No ",
        },
        // send the GraphQL query
        body: JSON.stringify({ query }),
      })
      .then((response) => response.json())
      .then(({ data, errors }) => {
        if (errors) {
          console.error(errors);
        }

        // rerender the entire component with new data
        setBlog(data.blogCollection.items);
      });
  }, [query]);
  if (!blog) {
    return "Loading...";
  }
  return (
    <Style>
      <div style={{}}>
        <TopBar />
      </div>

      <div className={style2.jumbo}>
        <div>
          <div className={style2.title}>
            <b> Our Blog</b>
          </div>
        </div>
      </div>

      <div class="design1">
        <Container>
          {blog.map((item) => (
            <div
              style={{
                marginBottom: "50px",
                backgroundColor: "white",
                borderRadius: "10px",
                padding: "20px",
              }}
            >
              <h1>{item.title}</h1>
              <div style={{ textAlign: "center", backgroundColor: "white" }}>
                <img
                  src={item.picture.url}
                  className="App-logo"
                  alt="pic"
                  style={{ width: "200px" }}
                />
              </div>
              <p style={{ fontSize: "20px" }}>
                {item.fullText.split("\n").map((par) => (
                  <div style={{ marginBottom: "8px" }}>{par}</div>
                ))}
              </p>
               {item.references.split("\n").map((ref) =>
               <p style={{ fontSize: "15px", textAlign: "left" }}>
                {ref}
              </p>
               )}
            </div>
          ))}
        </Container>
      </div>
    </Style>
  );
}
const Style = styled.div`
  .lili {
    margin-left: 0px;
    margin-right: 50px;
    margin-top: 20px;
    margin-bottom: 70px;
  }
  .center {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .jumbo {
    background: rgb(3, 17, 98);
    background: linear-gradient(
      90deg,
      rgba(3, 17, 98, 1) 0%,
      rgba(70, 171, 211, 1) 56%,
      rgba(231, 215, 107, 1) 100%
    );
    color: #ccc;

    position: relative;
    font-size: 50px;
  }
  @media (min-width: 1050px) {
    .title {
      padding-top: 9vw;
      text-align: center;
      margin-left: 30vw;
      margin-right: 30vw;

      font-size: 80px;
      color: orange;
    }
    .subTitle {
      text-align: center;
      margin-left: 20vw;
      margin-right: 20vw;
      font-size: 22px;
      padding-bottom: 50px;
      color: white;
    }
  }
  @media (max-width: 1199px) {
    .lili {
      text-align: center;
      padding: 10px;
    }
  }
  @media (max-width: 1050px) {
    .title {
      padding-top: 12vw;
      text-align: center;
      margin-left: 20vw;
      margin-right: 20vw;

      font-size: 80px;
      color: orange;
    }
    .subTitle {
      text-align: center;
      margin-left: 10vw;
      margin-right: 10vw;
      font-size: 22px;
      padding-bottom: 50px;
      color: white;
    }
  }
  @media (max-width: 768px) {
    .title {
      padding-top: 140px;
      text-align: center;
      font-size: 50px;
      color: orange;
    }
    .subTitle {
      text-align: center;
      margin-left: 5vw;
      margin-right: 5vw;
      font-size: 18px;
      padding-bottom: 50px;
      color: white;
    }
  }

  .design1 {
    padding: 10px;
    //border: 2px solid orange;
    //box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  .ourStory {
    background-color: #e4e5e6;

    padding: 10px;
  }
`;

import React, {Component, useState , useEffect } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import TopBar from './components/TopBar';
import { Footer } from './components/Footer';
import { TelephoneFill, EnvelopeFill } from 'react-bootstrap-icons'
import lili from "./assets/lili.png";
import style from './style2.module.css';
import {  ScrollToTop } from './ScrollToTop';

export default class Contact extends Component{ 
        componentDidMount(){
            ScrollToTop()
        }
        render(){
    return (
    
    
    <>  
        <div style={{}}>
            <TopBar />
        </div>
         
        <div className={style.jumbo}>
            <div>
                <div className={style.title}>
                <b> Contact Us</b>
                </div>
            
                <div className={style.subTitle}>
                
                        <p>Please reach out to us for more information  </p>
                    </div>
            </div>    
        </div>
               
        
        <div  style={{ backgroundColor: 'white', marginLeft: "30vw", marginRight: "30vw" }}>
            <Row style={{ padding: "0px", fontSize: "25px"}}>
                <Col style={{textAlign: "center", paddingTop: "50px"}}>Lili Deligianni - Founder</Col>

            </Row>
            <Row style={{position:'relative',  padding:'50px 0px'}}>
                <Col className={style.profileImage}>
                    <Image  src={lili} alt='liliDeligianni' height="200px" roundedCircle />
                </Col>
                <Col className={style.large}>
                            <TelephoneFill size={40} style={{ color: "black"}}/>
                            <div class={style.center}>
                            <b style={{fontSize:'30px'}}  >Phone</b>
                            <p >+1-201-665-6443</p>
                            </div>
                </Col>
                <Col className={style.large}>
                            < EnvelopeFill size={40} style={{ color: "black"}}/>
                            <div class={style.center}>
                                <b style={{fontSize:'30px'}} >Email</b>
                                <p >lili@sense4me.com</p>
                            </div>
                </Col>
            </Row>
            
        </div>
        <div  style={{ backgroundColor: 'white', marginLeft: "30vw", marginRight: "30vw", marginBottom: "50px" }}>
            <Row >
                <Col className={style.small}>
                            <TelephoneFill size={40} style={{ color: "black"}}/>
                            <div class={style.center}>
                            <b style={{fontSize:'30px'}}  >Phone</b>
                            <p >+1-201-665-6443</p>
                            </div>
                </Col>
                <Col className={style.small}>
                            < EnvelopeFill size={40} style={{ color: "black"}}/>
                            <div class={style.center}>
                                <b style={{fontSize:'30px'}} >Email</b>
                                <p >lili@sense4me.com</p>
                            </div>
                </Col>
            </Row>
        </div>
        <Footer />
        
    </>

)
    }
}


// function Example() {
//     //let content = { firstName: 'Fred' };

//     // The actual fetch request
//     //fetch('/api/post', {
//     //    method: 'post',
//       //  headers: {
//       //      'Content-Type': 'application/json'
//       //  },
//       //  body: JSON.stringify(content)
//    // })

//     const [currentTime, setCurrentTime] = useState(0);
    
//     useEffect(() => {
//         fetch('/api/hello').then(res => res.json()).then(data => {
//             setCurrentTime(data.hello);
//             //console.log(data);
//         });
//     }, []);

    
//     const axios = require('axios');
//     // Make a request for a user with a given ID
//     axios.get('/api/hello')
//         .then(function (response) {
//             // handle success
//             console.log(response);
//         })
//         .catch(function (error) {
//             // handle error
//             console.log(error);
//         })
    
//     return (
//         <div className="App">
//             <header className="App-header">
//                 <p>The current time is {currentTime}.</p>
                
//             </header>
//             <fetch />
//         </div>
//     );

// }

// function Ax() {
//     const axios = require('axios');

//     axios.post('/api/hello', {
//         firstName: 'Fred',
//         lastName: 'Flintstone'
//     })
//         .then(function (response) {
//             console.log(response);
//         })
//         .catch(function (error) {
//             console.log(error);
//         });
//     return null;

    
// }

    

/*neil kennedy
August 10, 2020
some code from GeeksforGeeks at https://www.geeksforgeeks.org/file-uploading-in-react-js/
more info on file input here https://developer.mozilla.org/en-US/docs/Web/API/File/Using_files_from_web_applications

*/

import axios from 'axios';

import React, { Component } from 'react';
import { Container } from 'react-bootstrap';

export const FileUpload = () => (
    <>
        < AxiosUpload />
    </>

)



class AxiosUpload extends Component {
    
    state = {

        // Initially, no file is selected 
        selectedFile: null,
        success: 'Uploading'
    };

    // On file select (from the pop up) 
    onFileChange = event => {

        // Update the state 
        this.setState({ selectedFile: event.target.files[0] });

    };

    // On file upload (click the upload button) 
    onFileUpload = () => {
        if (this.state.selectedFile != null) {
            // Create an object of formData 
            const formData = new FormData();

            // Update the formData object 
            formData.append(
                "file",
                this.state.selectedFile,
                this.state.selectedFile.name
            );

            // Details of the uploaded file 
            //console.log(this.state.selectedFile);

            // Request made to the backend api 
            // Send formData object 
            let headerInfo = {
                headers: {
                    "Content-type": "multipart/form-data"
                }
            };
            axios.post("/api/upload", formData, headerInfo)
                .then(function (response) {
                    console.log(response);
                })
                .catch(function (error) {
                    console.log(error);
                });;
        } else {
            console.log(this.state.selectedFile);
        }
        
    };

    dbPush = () => {
        
        //make a get request to database to run ../api/addData.py
        axios.get('/api/dbPush')
            .then(function (response) {
                // handle success
                
                console.log(response);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
    }
    

    // File content to be displayed after 
    // file upload is complete 
    fileData = () => {

        if (this.state.selectedFile) {

            return (
                <div>
                    <h2>File Details:</h2>
                    <p>File name: {this.state.selectedFile.name}</p>
                    <p>File type: {this.state.selectedFile.type}</p>
                    <p>
                        Last Modified:{" "}
                        {this.state.selectedFile.lastModifiedDate.toDateString()}
                    </p>
                </div>
            );
        } else {
            return (
                <div>
                    <br />
                    <h4>Choose file before Pressing the Upload button</h4>
                </div>
            );
        }
    };

    render() {

        return (
            <Container>
                <div>
                    
                    <h3>
                        File Upload
                     
                    </h3>
                    <p4> For multiple files, choose file 1, then upload, then choose file 2, and upload again before pushing to database
                    </p4>
                    <div>
                        <input type="file" onChange={this.onFileChange}  />
                        <button onClick={this.onFileUpload}>
                            Upload!
                        </button>
                    
                        <button onClick={this.dbPush}>
                            Push to Database!
                        </button>
                    
                    </div>
                    {this.fileData()}
                </div>
            </Container>
        );
    }
}

//VictoryChart documentation here for graphs https://formidable.com/open-source/victory/docs
import React, { Component } from 'react'
import jwt_decode from 'jwt-decode'
import { AccountBar } from './components/AccountBar'
import { Footer } from './components/Footer'
import styled from 'styled-components'
import { VictoryLine, VictoryChart, VictoryAxis, VictoryPie, VictoryLabel, VictoryTheme, VictoryContainer} from 'victory';
import axios from 'axios';

const Style=styled.div`
    .title{
        margin-left: 100px;
    }
    .info{
        margin-left: 100px;
    }
`
const data = [
    {quarter: 1, earnings: 13000},
    {quarter: 2, earnings: 16500},
    {quarter: 3, earnings: 14250},
    {quarter: 4, earnings: 19000}
];


class Settings extends Component {
    constructor() {
        super()
        this.state = {
            first_name: '',
            last_name: '',
            email: '',
            errors: {},
            data: [],
            idNum: ''
        }
    }
    
    componentDidMount() {
        const token = localStorage.usertoken
        //console.log(localStorage.usertoken)
        const decoded = jwt_decode(token);

        this.setState({
            first_name: decoded.identity.first_name,
            last_name: decoded.identity.last_name,
            user_name: decoded.identity.user_name,
            email: decoded.identity.email,
            idNum: decoded.identity.idNum
            

        })
        
        axios.post('api/conductance', {idNum: ''+decoded.identity.idNum} )
        .then(response => {
          this.setState({
            data: Object.values(response.data)
          })
          
        })
        
      
    }
    
    render() {
        return (
            <Style>

                <div style={{height:'100px'}}>
                    <AccountBar />
                </div>

                <div class='title'>
                    <h2>
                        Dashboard
                    </h2>
                </div>
                <div class='info'>
                    <p>First Name: {this.state.first_name}</p>

                    <p>Lask Name: {this.state.last_name}</p>
                    <p> Email: {this.state.email}</p>
                    <p> Username: {this.state.user_name}</p>
                    <p> IdNum: {this.state.idNum}</p>
                </div>
                <div style={{marginLeft:'20vw', marginBottom:'20vw', backgroundColor:'', width:'30vw', position: 'relative'}}>
                    

                    
                    <VictoryChart style={{position:'relative'}} domainPadding={0}   >
                        
                        <VictoryAxis  label="Time (ms)" style={{ tickLabels: { padding: 0, fontSize: 10 } }} />
                        <VictoryAxis dependentAxis label="Conductance (100microsiemens)" style={{ tickLabels: { padding: 0, fontSize: 10 } }}/>
                        <VictoryLine width={1} style={{data: { stroke: "orange" , strokeWidth: .5 }}}
                            data={this.state.data}
                            // data accessor for x values
                            x="time"
                            // data accessor for y values
                            y="conductance"
                        />
                    </VictoryChart>
                    
                </div>
                
                <Footer />
            </Style>
            
        )
    }
}

export default Settings

import React from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import photo from '../assets/SGradientComponent.png';

const Styles = styled.div`
    .navbar {
        background-color: black;
        position:  relative;
        bottom: 0;
        width: 100%;
        
        font-size: 20px;
    }
    a, .navbar-brand, .navbar-nav, .nav-link{
        color: gray;
        &: hover {
            color: white;
        }
        
    }
    .
`;

export const Footer = () => (
    <Styles>
        <nav class="navbar">
            
            <Nav class='mx-auto'>
                <Nav.Link>
                    <Link to="/"> Home </Link>
                    <a>|</a>
                    <Link to="/mentalhealth"> Solution </Link>
                    <a>|</a>
                    <Link to="/about"> About </Link>
                    <a>|</a>
                    <Link to="/contact"> Contact </Link>
                </Nav.Link>
            </Nav>
        </nav>
        <div style={{backgroundColor:'black', textAlign:'center', height:'300px'}}>
        <Navbar.Brand href="/">
                <img
                    alt='sense4me'
                    src={photo}
                    height="100"
                    className="d-inline-block align-top"
                />{' '}
               
            </Navbar.Brand>
            <p></p>
            <p style={{color:'gray'}}>sense4me</p>
            <p style={{color:'gray'}}>phone: +1-201-665-6443</p>
            <p style={{color:'gray'}}>email: lili@sense4me.com</p>
        </div>
    </Styles>
)
import React, { useState , useEffect, useRef, Component } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import TopBar from './components/TopBar';
import { Footer } from './components/Footer';
import styled from 'styled-components';
import {  ScrollToTop,  ScrollToChronic, ScrollToMental } from './ScrollToTop';
import Timeline from './assets/Timeline.jpg';  //from visme.com
import YouTubePlayer from 'react-player';
import ScrollableSection, { ScrollableLink } from 'react-update-url-on-scroll';
import { render } from '@testing-library/react';
//background: rgb(2,0,36);
//background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(241,244,255,1) 0%, rgba(142,209,222,1) 100%);
import style2 from './style2.module.css';
        
const Style = styled.div`
    .center {
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        
    }
    .jumbo {
        background: rgb(3,17,98);
        background: linear-gradient(90deg, rgba(3,17,98,1) 0%, rgba(70,171,211,1) 56%, rgba(231,215,107,1) 100%);
        color: #ccc;
        height: 400px;
        
        position: relative;
        font-size: 50px;
        
        
    }
    .video {
        text-align: center;
        padding: 30px;
        background-color: #003f72;
        color: white;
        font-size: 20px;
        padding-left: 15vw;
        padding-right: 15vw;
    }
    .timeline{
        height: 95%;
    }
    @media(min-width:1050px){
        .title {
            padding-top: 10px;
            font-size: 80px;
            color: orange;
        }
        .subTitle {
            text-align: center;
            
            font-size: 28px;
            
            color: white;
          }
        //   .timeline{
        //     height: 400px;
        //   }
    }
    
    @media(max-width:1050px){
        .title {
            padding-top:10px;
            font-size: 80px;
            color: orange;
        }
        .subTitle {
            text-align: center;
            
            font-size: 22px;
            
            color: white;
          }
        //   .timeline{
        //     height: 300px;
        // }
          
    }
    @media(max-width:768px){
        .title {
            padding-top:10px;
            text-align: center;
            font-size: 50px;
            color: orange;
        }
        .subTitle {
            text-align: center;
            
            font-size: 18px;
            
            color: white;
          }
       .video{
           padding:0px;
       }
       .timeline{
            height: 60%;
        }
       
    }
    
    
    
`

export default class Products extends Component{ 
        componentDidMount(){
            ScrollToTop()
        }
        render(){
        
    return(
    
    
    <Style>
        <TopBar />
        
        <div className={style2.jumbo}>
                <div className={style2.title}>
                    <b> Infectious Diseases</b>
                </div>
            
                <div className={style2.subTitle}>
                    Real-time monitoring of signals to track disease prediction and immune system response
                </div>
        </div>




                
{/* Covid and Infections */}
        <ScrollableSection name={'covid'} meta={'title'} >
            
                
            <Row style={{padding:'30px', backgroundColor:'white', color: "black", maxWidth:"100%"}}>
                <Col style={{ fontSize: "20px", margin: '10px' }}>
                    
                    <Row  >
                        <h2 style={{textAlign:"left", fontSize: "40px", color: "orange"}}><b>Timely Intervention</b></h2>
                        <p>Sense4me is currently developing a combination of physiological and biological sensors 
                            to track the progression of Covid-19 and other infectious diseases in real time</p>
                    </Row>
                    <Row  >
                        <p>With Covid-19, indication of disease severity will allow for timely intervention 
                            depending on disease stage and prevention of the 'cytokine storm'</p>
                    </Row>
                </Col>
                
                <Col >
                    <img src={Timeline} alt='timeline' flexShrink='1' className= 'timeline' />
                </Col>
            </Row>
            
            <div class='video' >
                    <p>Featured at the National Academy of Engineering Covid-19 Pitch Event</p>
                    <YouTubePlayer
                    width='100%'
                    height='30vw'
                    url='https://youtu.be/lyqwgUQOq9o?t=3727'
                    allowFullScreen='true'
                    />
            </div>
        </ScrollableSection>


{/* Chronic Illness
<ScrollableSection name={'chronic-illness'} meta={'title'} >
            <Row  style={{ fontSize: "30px", padding: "70px" }}>
                <Col >
                    <h2 style={{textAlign:"left", fontSize: "40px"}}><b>Chronic Illness</b></h2>
                    <p>Sense4me is developing sensors to track dynamic fluctuations of stress response and 
                        inflammation in chronic autoimmune disorders.</p>
                </Col>
                
            </Row>
            <Row style={{padding:'30px', backgroundColor:'#003f72', color: "white"}}>
                <Col style={{ fontSize: "20px", margin: '10px' }}>
                    
                    <Row  >
                        <h2 style={{textAlign:"left", fontSize: "40px", color: "orange"}}><b>Flare-up Prediction</b></h2>
                        <p>This can predict the cause of flare-ups, leading to improvement in treatment efficacy 
                        for patients with autoimmune diseases. Remote monitoring can assist patients and doctors
                        with long-term tracking of chronic conditions and changes over long periods of time.</p>
                    </Row>
                    <Row  >
                        <p>Patients will better be able to understand their condition and seek guidance from 
                        information produced by the Sense4me app. Doctors will better be able to inform patients 
                        with increased information collected in real time.</p>
                    </Row>
                </Col>
                
                
            </Row>
        </ScrollableSection>
                 */}
       
        
        <Footer />
        
    </Style>

    )
    }
}


///the rest is old code I've just kept around for testing
function Example() {
    //let content = { firstName: 'Fred' };

    // The actual fetch request
    //fetch('/api/post', {
    //    method: 'post',
      //  headers: {
      //      'Content-Type': 'application/json'
      //  },
      //  body: JSON.stringify(content)
   // })

    const [currentTime, setCurrentTime] = useState(0);
    
    useEffect(() => {
        fetch('/api/hello').then(res => res.json()).then(data => {
            setCurrentTime(data.hello);
            //console.log(data);
        });
    }, []);

    
    const axios = require('axios');
    // Make a request for a user with a given ID
    axios.get('/api/hello')
        .then(function (response) {
            // handle success
            console.log(response);
        })
        .catch(function (error) {
            // handle error
            console.log(error);
        })
    
    return (
        <div className="App">
            <header className="App-header">
                <p>The current time is {currentTime}.</p>
                
            </header>
            <fetch />
        </div>
    );

}

function Ax() {
    const axios = require('axios');

    axios.post('/api/hello', {
        firstName: 'Fred',
        lastName: 'Flintstone'
    })
        .then(function (response) {
            console.log(response);
        })
        .catch(function (error) {
            console.log(error);
        });
    return null;

    
}

    



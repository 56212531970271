//some elements using react bootstrap https://react-bootstrap.github.io/getting-started/introduction/
//colors using react color scheme https://casesandberg.github.io/react-color/

import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./Home";
import About from "./About";
import Contact from "./Contact";
import { NoMatch } from "./NoMatch";
import Login from "./Login";
import Register from "./Register";
import Settings from "./Settings";
import Activity from "./Activity";
import Dashboard from "./Dashboard";
import { FileUpload } from "./FileUpload";
import MentalHealth from "./MentalHealth";
import Infectious from "./Infectious";
import Blog from "./Blog";
import CookieConsent from "react-cookie-consent";

class App extends Component {
  render() {
    return (
      <div
        style={{
          backgroundColor: "white",
        }}
      >
        <React.Fragment>
          <Router>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/fileupload" component={FileUpload} />
              <Route path="/about" component={About} />
              <Route path="/contact" component={Contact} />
              <Route path="/login" component={Login} />
              <Route path="/register" component={Register} />
              <Route path="/dashboard" component={Dashboard} />
              <Route path="/activity" component={Activity} />
              <Route path="/settings" component={Settings} />
              <Route path="/mentalhealth" component={MentalHealth} />
              <Route path="/infectious" component={Infectious} />
              <Route path="/blog" component={Blog} />
              <Route component={NoMatch} />
            </Switch>
          </Router>
        </React.Fragment>
        <CookieConsent>
          This website uses cookies, please see our <a href="https://app.termly.io/document/privacy-policy/5a5acf6c-8ffc-4e31-a98a-0b802db59111

" target='_blank' rel="noopener noreferrer">privacy policy</a> and <a href='https://app.termly.io/document/cookie-policy/cf2298c8-e6cb-4351-ba66-bd179f500010' target='_blank' rel="noopener noreferrer">cookie policy</a>
        </CookieConsent>
      </div>
    );
  }
}

export default App;

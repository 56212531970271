import React, { Component } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import neil from "./assets/neil.jpg";
import natalie from "./assets/natalie.jpg";
import lili from "./assets/lili.png";
import naqvi from "./assets/naqvi.png";
import TopBar from "./components/TopBar";
import { Footer } from "./components/Footer";
import styled from "styled-components";
import { ScrollToTop } from "./ScrollToTop";
import style2 from "./style2.module.css";

const Style = styled.div`
  .lili {
    margin-left: 0px;
    margin-right: 50px;
    margin-top: 20px;
    margin-bottom: 70px;
  }
  .center {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .jumbo {
    background: rgb(3, 17, 98);
    background: linear-gradient(
      90deg,
      rgba(3, 17, 98, 1) 0%,
      rgba(70, 171, 211, 1) 56%,
      rgba(231, 215, 107, 1) 100%
    );
    color: #ccc;

    position: relative;
    font-size: 50px;
  }
  @media (min-width: 1050px) {
    .title {
      padding-top: 9vw;
      text-align: center;
      margin-left: 30vw;
      margin-right: 30vw;

      font-size: 80px;
      color: orange;
    }
    .subTitle {
      text-align: center;
      margin-left: 20vw;
      margin-right: 20vw;
      font-size: 22px;
      padding-bottom: 50px;
      color: white;
    }
  }
  @media (max-width: 1199px) {
    .lili {
      text-align: center;
      padding: 10px;
    }
  }
  @media (max-width: 1050px) {
    .title {
      padding-top: 12vw;
      text-align: center;
      margin-left: 20vw;
      margin-right: 20vw;

      font-size: 80px;
      color: orange;
    }
    .subTitle {
      text-align: center;
      margin-left: 10vw;
      margin-right: 10vw;
      font-size: 22px;
      padding-bottom: 50px;
      color: white;
    }
  }
  @media (max-width: 768px) {
    .title {
      padding-top: 140px;
      text-align: center;
      font-size: 50px;
      color: orange;
    }
    .subTitle {
      text-align: center;
      margin-left: 5vw;
      margin-right: 5vw;
      font-size: 18px;
      padding-bottom: 50px;
      color: white;
    }
  }

  .design1 {
    padding: 10px;
    //border: 2px solid orange;
    //box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  .ourStory {
    background-color: #e4e5e6;

    padding: 10px;
  }
`;

class About extends Component {
  componentDidMount() {
    ScrollToTop();
  }
  render() {
    return (
      <Style>
        <div style={{}}>
          <TopBar />
        </div>

        <div className={style2.jumbo}>
          <div>
            <div className={style2.title}>
              <b> Our Vision</b>
            </div>

            <div className={style2.subTitle}>
              <p>
                Our vision is to become the leading provider of personalized
                health analytics. We aim to transform the standard of care for
                mental health and infectious diseases. Our platform empowers
                patients and medical professionals to manage addictions and
                mental health conditions.
              </p>
            </div>
          </div>
        </div>

        <div class="design1">
          <h1 style={{ textAlign: "center", marginTop: "20px" }}>Our Team</h1>
        </div>

        <Container>
          <Row className="lili">
            <Col style={{ textAlign: "right", marginRight: "5vw" }}>
              <Image src={lili} alt="liliDeligianni" roundedCircle />
            </Col>
            <Col>
              <h2 style={{ marginTop: "20px" }}>Founder - Lili Deligianni </h2>
              <p style={{ fontSize: "18px" }}>
                Lili Deligianni has three decades of industrial experience and
                played a leading role in the successful introduction of
                electrochemical technology in computer chips. She has a proven
                track record in the commercialization of technologies. She is a
                prolific inventor with more than 230 patents and is the
                recipient of the 2006 NY Inventor of the Year award. Dr.
                Deligianni has been recognized with numerous awards including
                her election in the National Academy of Engineering. Her
                experiences include nanotechnology and electrochemical sensors,
                bioelectronics and brain-computer interfaces. She has worked
                with diverse global teams across many geographies.{" "}
              </p>
            </Col>
          </Row>
        </Container>

        <div style={{ backgroundColor: "#e6e9e8", padding: "20px" }}>
          <Row
            style={{
              textAlign: "center",
              fontSize: "20px",
              marginBottom: "50px",
              padding: "40px",
            }}
          >
            <Col>
              <p>
                <b> Nasir Naqvi, MD, PhD </b>
              </p>
              <Image
                src={naqvi}
                alt="nasirNaqvi"
                style={{ width: "180px", marginBottom: "10px" }}
                roundedCircle
              />
              <p>Head Psychiatrist </p>
              <p style={{ fontSize: "17px" }}>
                Dr. Naqvi is a psychiatrist and cognitive neuroscientist focused
                on addictive disorders. He is developing our mental health use
                case to help treat substance use disorders with real-time
                monitoring of signals to predict and inform patients. He is a
                recipient of the Louis V. Gerstner, Jr., Award for translational
                research at Columbia University Medical Center. He has also
                received the Outstanding Resident Award from the NIMH, and the
                Laughlin Fellowship from the American College of Psychiatrists.{" "}
              </p>
            </Col>

            <Col>
              <p>
                <b> Neil Kennedy </b>{" "}
              </p>
              <Image
                src={neil}
                alt="neilKennedy"
                style={{ width: "180px", marginBottom: "10px" }}
                roundedCircle
              />
              <p>Engineer</p>
              <p style={{ fontSize: "17px" }}>
                Neil is a biomedical engineer and graduate of Columbia
                University. He has contributed to the software development at
                sense4me for over two years, including developing a preliminary
                full web stack and mobile application, as well as feature
                analysis on physiological and drinking data to develop a machine
                learning predictor of drinking patterns. He has also worked as a
                software engineer to develop research tools at the New York Stem
                Cell Foundation, and as an engineering fellow at Ro to improve
                the safety of their online pharmacy.
              </p>
            </Col>

            <Col>
              <p>
                <b> Natalie Leventis </b>{" "}
              </p>
              <Image
                src={natalie}
                alt="natalieleventis"
                style={{ width: "180px", marginBottom: "10px" }}
                roundedCircle
              />
              <p>Engagement officer</p>
              <p style={{ fontSize: "17px" }}>
                Natalie graduated from Barnard College with a Bachelor’s in
                Economics. She is now studying to become a National
                Board-Certified Health and Wellness Coach. Using behavioral
                change methodology, she aims to support individuals in achieving
                their health and wellness goals. At Sense4me, Natalie
                participates in addiction research and engages with clients to
                improve the S4Me user experience.
              </p>
            </Col>
          </Row>
        </div>

        <div style={{ backgroundColor: "white", padding: "20px" }}></div>
        <Footer />
      </Style>
    );
  }
}
export default About;

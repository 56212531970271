import React, { useState , useEffect, useRef, Component } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import TopBar from './components/TopBar';
import { Footer } from './components/Footer';
import {  ScrollToTop,  ScrollToChronic, ScrollToMental } from './ScrollToTop';
import Timeline from './assets/Timeline.jpg';  //from visme.com
import YouTubePlayer from 'react-player';
import ScrollableSection, { ScrollableLink } from 'react-update-url-on-scroll';
import { render } from '@testing-library/react';
import video2 from './assets/ycapp.mp4';
import wearable from './assets/wearable.png'
import predictor from './assets/predictor.png'
import phone from './assets/phone.png'
import needCase from './assets/needCase.png'

//background: rgb(2,0,36);
//background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(241,244,255,1) 0%, rgba(142,209,222,1) 100%);
import style2 from './style2.module.css'; 


export default class Products extends Component{ 
        componentDidMount(){
            ScrollToTop()
        }
        render(){
        
    return(
    
    
    <>
        <TopBar />
        {/* Jumbo */}
        <div className={style2.jumbo}>
                <div className={style2.title}>
                    <b> Mental Health</b>
                </div>
            
                <div className={style2.subTitle}>
                    Data driven goal setting for addiction and mental health disorders
                </div>
        </div>

        {/* Content */}
        <div className = {style2.contentDiv}>
            <div>
            <h2 style={{margin:"3vw",textAlign:"center", fontSize: "40px", color: "orange"}}><b>Treating Alcohol Use Disorder</b></h2>
            </div>
            <div style={{marginLeft: "5vw", marginRight: "5vw",textAlign:"center", fontSize: "20px"}}>
                In the United States, there is a significant need to improve treatment for alcohol use disorder.
                Currently, 98% of patients do not receive treatment. Sense4me is developing a way to reduce drinking in Alcohol Use Disorder patients through the use of 
                physiological sensors and Cognitive Behavioral Therapy. This will enable increased access and effectiveness of treatment.


            </div>
            <div style={{marginBottom: "50px",textAlign:"center"}}>
                <img style={{margin: "5vw"}} src={wearable} height="200px" width="160px"/>
                
                <img style={{margin: "5vw"}} src={phone} height="200px" width="120px"/> 
            </div>
                   
        </div>


        
{/* 

{/* Mental Health */}{/*}
            
        <Row style={{backgroundColor: 'white', color: "black", marginBottom: "20px"}}>
                <Col style={{ fontSize: "20px", margin: '10px' }}>
                    
                    <Row  >
                        <h2 style={{textAlign:"left", fontSize: "40px", color: "orange"}}><b>Relapse Prediction</b></h2>

                        <p>A just-in-time intervention is needed to treat cravings and reduce relapse rate.  To address the unmet need of treating relapse, we are building real-time measurements from the S4Me wearable sensors. The wearable measures the response to stress in real-time from the sympathetic nervous system and the hypothalamus. Dysregulation of the stress system has been associated with a shorter time to future relapses in alcoholics.
                        </p>


                        <p>Real-time monitoring of stress response can identify and predict relapses in patients with 
                            addictive disorders. It can also allow for prediction and early intervention of flare-ups for mental health 
                            disorders such as anxiety and depression. </p>
                    </Row>
                    <Row  >
                        <p>Users will be able to use information from the S4Me app to gain quantitative insight into their conditions 
                        and how to predict and avoid flare-ups. </p>
                    </Row>
                </Col>
                
                
            </Row>
                
        
            <Row className={style2.infoDiv}>
                
                
                <Col>
                    
                    <video src={video1} width='90%'  controls="controls"/>

                </Col>
            </Row>
        */}



{/* Chronic Illness
<ScrollableSection name={'chronic-illness'} meta={'title'} >
            <Row  style={{ fontSize: "30px", padding: "70px" }}>
                <Col >
                    <h2 style={{textAlign:"left", fontSize: "40px"}}><b>Chronic Illness</b></h2>
                    <p>Sense4me is developing sensors to track dynamic fluctuations of stress response and 
                        inflammation in chronic autoimmune disorders.</p>
                </Col>
                
            </Row>
            <Row style={{padding:'30px', backgroundColor:'#003f72', color: "white"}}>
                <Col style={{ fontSize: "20px", margin: '10px' }}>
                    
                    <Row  >
                        <h2 style={{textAlign:"left", fontSize: "40px", color: "orange"}}><b>Flare-up Prediction</b></h2>
                        <p>This can predict the cause of flare-ups, leading to improvement in treatment efficacy 
                        for patients with autoimmune diseases. Remote monitoring can assist patients and doctors
                        with long-term tracking of chronic conditions and changes over long periods of time.</p>
                    </Row>
                    <Row  >
                        <p>Patients will better be able to understand their condition and seek guidance from 
                        information produced by the Sense4me app. Doctors will better be able to inform patients 
                        with increased information collected in real time.</p>
                    </Row>
                </Col>
                
                
            </Row>
        </ScrollableSection>
                 */}
       
        
        <Footer />
        
    </>

    )
    }
}


///the rest is old code I've just kept around for testing
function Example() {
    //let content = { firstName: 'Fred' };

    // The actual fetch request
    //fetch('/api/post', {
    //    method: 'post',
      //  headers: {
      //      'Content-Type': 'application/json'
      //  },
      //  body: JSON.stringify(content)
   // })

    const [currentTime, setCurrentTime] = useState(0);
    
    useEffect(() => {
        fetch('/api/hello').then(res => res.json()).then(data => {
            setCurrentTime(data.hello);
            //console.log(data);
        });
    }, []);

    
    const axios = require('axios');
    // Make a request for a user with a given ID
    axios.get('/api/hello')
        .then(function (response) {
            // handle success
            console.log(response);
        })
        .catch(function (error) {
            // handle error
            console.log(error);
        })
    
    return (
        <div className="App">
            <header className="App-header">
                <p>The current time is {currentTime}.</p>
                
            </header>
            <fetch />
        </div>
    );

}

function Ax() {
    const axios = require('axios');

    axios.post('/api/hello', {
        firstName: 'Fred',
        lastName: 'Flintstone'
    })
        .then(function (response) {
            console.log(response);
        })
        .catch(function (error) {
            console.log(error);
        });
    return null;

    
}

    



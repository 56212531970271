//some code for hamburger menu and scalable navbar from https://github.com/dieudonneAwa/responsive-navbar/blob/master/src/components/Nav/RightNav.jsx
import React from "react";
import styled from "styled-components";
import { Nav, Navbar, Container, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";

const Style = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
  .navStyle {
    a,
    .navbar-nav,
    .nav-link {
      color: #002138;
      font-size: 18px;
      font-weight: 400;
      &: hover {
        color: grey;
        text-decoration: none;
      }
    }
  }
`;

const Ul = styled.ul`
  @media (min-width: 768px) {
    display: none;
  }
  @media (max-width: 768px) {
    flex-flow: column nowrap;
    background-color: gray;
    color: white;
    position: fixed;
    transform: ${({ open }) => (open ? "translateX(0)" : "translateX(100%)")};
    top: 0;
    right: 0;
    height: 100vh;
    width: 200px;
    padding-top: 3.5rem;
    transition: transform 0.3s ease-in-out;
  }
`;

const RightNav = ({ open }) => {
  return (
    <>
      {/* Phone Layout */}
      <Ul open={open}>
        <Nav.Link>
          <Link style={{ color: "white" }} to="/mentalHealth">
            {" "}
            MENTAL HEALTH{" "}
          </Link>
        </Nav.Link>

        <Nav.Link>
          <Link style={{ color: "white" }} to="/about">
            {" "}
            ABOUT{" "}
          </Link>
        </Nav.Link>

        <Nav.Link>
          {/* <Link style={{ color: "white" }} to="/contact">
            {" "}
            CONTACT{" "}
          </Link> */}
        </Nav.Link>

        <Nav.Link>
          <Link style={{ color: "white" }} to="/blog">
            {" "}
            BLOG{" "}
          </Link>
        </Nav.Link>

        {/* <Nav.Link >
            <Link style={{color:'white'}} to="/login"> My account </Link>
        </Nav.Link>     */}
      </Ul>
      {/* Computer Layout */}
      <Nav className="mr-auto">
        <Style>
          <Nav className="navStyle">
            <Nav.Link>
              <Link to="/MentalHealth"> SOLUTION </Link>
            </Nav.Link>
            {/* <Dropdown>
              <Dropdown.Toggle
                style={{
                  backgroundColor: "white",
                  color: "black",
                  border: "none",
                  fontSize: "18px",
                  marginTop: "2px",
                }}
              >
                SOLUTION
              </Dropdown.Toggle> */}

            {/* <Dropdown.Menu>
                <Dropdown.Item>
                  <Nav.Link>
                    <Link to="/MentalHealth"> Mental Health </Link>
                  </Nav.Link>
                </Dropdown.Item>

                <Dropdown.Item>
                  <Nav.Link>
                    <Link to="Infectious">Infectious Diseases </Link>
                  </Nav.Link>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown> */}
            <Nav.Link>
              <Link to="/about"> ABOUT </Link>
            </Nav.Link>

            <Nav.Link>
              <Link to="/contact"> CONTACT </Link>
            </Nav.Link>

            <Nav.Link>
              <Link to="/blog"> BLOG </Link>
            </Nav.Link>
          </Nav>
        </Style>
      </Nav>
      {/* <Style>
    <Nav className="navStyle">
        <Nav.Link >
            
            {localStorage.usertoken ? <Link to="/dashboard"> My account </Link> : <Link to="/login"> My account </Link>}
        </Nav.Link> 
    </Nav>
       
    </Style> */}
    </>
  );
};

export default RightNav;

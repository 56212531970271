import React, {Component} from 'react';
import { Nav, Navbar, Container } from 'react-bootstrap';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import photo from '../assets/SLogo.png';
import logo2 from '../assets/S4meGradientComponent.png';
import Burger from './Burger'

const Styles = styled.div`
    .navbar {
        
        position: fixed; /* Make it stick/fixed */
        top: 0; /* Stay on top */
        width: 100%; /* Full width */
        /*transition: position 0.3s;*/
        z-index:99;
    }
    
    
`;

class TopBar extends Component{
    constructor(){
        super()
        this.state = {
            pos: '0',
            bgColor: '',
            mar: '0px'
        }
    }
    componentDidMount(){
        
            this.setState({pos: '50'});
            this.setState({bgColor: 'white'})
            this.setState({mar: '0px'})
        
    }
render(){
    return(
        
    <Styles>
        
        <nav class="navbar" style = {{backgroundColor: this.state.bgColor, marginTop: this.state.mar}}>
            <Container>
            {/* <Navbar.Brand> */}
                <Link to="/">
                <img
                    alt='sense4me'
                    src={logo2}
                    height={this.state.pos}
                    className="d-inline-block align-top"
                />{' '}
                </Link>
                
            {/* </Navbar.Brand> */}
            
            
            <Burger />
            
            </Container>
        </nav>
        
    
    </Styles>
    )

    }
}
export default TopBar
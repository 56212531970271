import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

// function ScrollToTop({ history }) {
//   useEffect(() => {
//     const unlisten = history.listen(() => {
//       window.scrollTo(0, 0);
//     });
//     return () => {
//       unlisten();
//     }
//   }, []);

//   return (null);
// }

//export default withRouter(ScrollToTop);



export const ScrollToTop = function() {
  window.scrollTo(0, 0);
  

}
export const ScrollToInfectious = function() {
  var height1= 480;
  window.scrollTo(0, height1);
  

}

export const ScrollToChronic = function() {
  var height2= 550000/(window.innerWidth+400)+480;
  window.scrollTo(0, height2);
  

}

export const ScrollToMental = function() {
  var height3= 12000000/(window.innerWidth+400)+480;
  window.scrollTo(0, height3);
  

}


//This is the login page
//some code inspired from https://github.com/ArjunAranetaCodes/MoreCodes-Youtube/blob/master/react-flask-mysql-login-reg/src/components/

import React, { Component } from 'react'
import { login } from './components/UserFunctions'
import TopBar from './components/TopBar'
import {Footer} from './components/Footer'
import styled from 'styled-components';

const Style = styled.div`
    background-color: #bcc4c6;
    
    .btn {
            
        border: 2px solid black;
        background-color: white;
        
        padding: 14px 28px;
        font-size: 16px;
        cursor: pointer;
    }
    .submit {
        
        position: absolute;
        bottom: 10%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        border-color: #ff9800;
        color: orange;
    }
    
    .submit:hover {
    background: #ff9800;
    color: white;
    }
`

class Login extends Component {
  constructor() {
    super()
    this.state = {
      email: '',
      password: '',
      errors: {}
    }

    this.onChange = this.onChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }
  onSubmit(e) {
    e.preventDefault()

    const user = {
      email: this.state.email,
      password: this.state.password
    }

    login(user).then(res => {
        this.setState({invalid:"" })
        
        
      try{
        if(!res.error) {
            this.props.history.push(`/dashboard`)
        } 
        
      }
      catch{
        this.props.history.push(`/login`)
        setTimeout(function() { //Start the timer
            this.setState({invalid:"Invalid Login Information"}) //After 1 second, set render to true
        }.bind(this), 1000)
       
        
      }
      
    })
  }

  render() {
    return (
        <Style>
        <div style={{height:'60px'}}>
        <TopBar />
        </div>
      <div className="container" style={{marginBottom: '75px'}}>
        <div className="row">
          <div className="col-md-6 mt-5 mx-auto">
            <form noValidate onSubmit={this.onSubmit}>
              <h1 className="h3 mb-3 font-weight-normal">Sign in to account to access device data</h1>
              <div className="form-group">
                <label htmlFor="email">Email address</label>
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  placeholder="Enter email"
                  value={this.state.email}
                  onChange={this.onChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  className="form-control"
                  name="password"
                  placeholder="Password"
                  value={this.state.password}
                  onChange={this.onChange}
                />
              </div>
              <div style={{position:'relative', height: '100px'}}>
                <button
                    type="submit"
                    class="btn submit"
                    
                >
                    Sign in
                </button>
              </div>
              
            </form>
            <div>
                <p style={{color:'red'}}>{this.state.invalid}</p>
            </div>
            
            <div>
                Need an account? Register <a href={'./register'}>Here</a>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      </Style>
    )
  }
}


export default Login
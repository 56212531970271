import React from 'react';
import { Nav, Navbar, Container } from 'react-bootstrap';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import photo from '../assets/SLogo.png';
import logo2 from '../assets/SGradientComponent.png';
import AccountBurger from './AccountBurger'

const Styles = styled.div`
    .navbar {
        background-color: white;
        position: fixed; /* Make it stick/fixed */
        top: 0; /* Stay on top */
        width: 100%; /* Full width */
        /*transition: position 0.3s;*/
        z-index:99;
    }
    
    
`;


export const AccountBar = () => (
    <Styles>
        
        <nav class="navbar">
            <Container>
            <Navbar.Brand>
                <Link to="/">
                <img
                    alt='sense4me'
                    src={logo2}
                    height="50"
                    className="d-inline-block align-top"
                />{' '}
                </Link>
                
            </Navbar.Brand>
            
            
            <AccountBurger />
            
            </Container>
        </nav>
        
    
    </Styles>
)
import React, { Component, useRef, useState, useCallback } from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { Link } from "react-router-dom";
import TopBar from "./components/TopBar";
import { Footer } from "./components/Footer";

import { ScrollToTop } from "./ScrollToTop";
import sky from "./assets/sky.jpg";
import lightbulb from "./assets/lightbulb.png";
import mail from "./assets/mail.png";
import brain from "./assets/brain.png";
import { Spring } from "react-spring/renderprops";
import { useSpring, animated } from "react-spring";
import style from "./style1.module.css";
import VisibilitySensor from "react-visibility-sensor";

class Home extends Component {
  componentDidMount() {
    ScrollToTop();
  }
  render() {
    return (
      <>
        <div class={style.topBar}>
          <TopBar />
        </div>
        <div class={style.jumbo}>
          <img class={style.skyImage} src={sky} alt="sky" />
          <Spring
            from={{ opacity: 0.5 }}
            to={{ opacity: 1 }}
            config={{ duration: 1000 }}
          >
            {(props) => (
              <div style={props}>
                <div className={style.center}>
                  <b className={style.title}>sense4me</b>
                  <p className={style.subTitle}>
                    Innovation. Information. Intelligence.{" "}
                  </p>
                </div>
              </div>
            )}
          </Spring>
        </div>

        <div class={style.vision}>
          <h2 class={style.visionTitle}>
            <b>What We Do</b>
          </h2>
          {/* <p style={{textAlign:'center', fontSize:'20px'}}>We are a digital healthcare and wellness data provider. We are building a digital platform that will focus on disease conditions having to do with stress and inflammation with implications in mental health, autoimmune diseases, infectious diseases and certain cancers. We measure disease-specific markers of stress and inflammation with a wearable patch and use deep-learning to provide clinicians and researchers with patient-specific data and insights on disease progression and management.</p>
           */}
          <Row>
            <Col className={style.visionText}>
              <img
                src={lightbulb}
                width="100px"
                height="100px"
                alt="lightbulb"
              />
              <h2 style={{ color: "#003f72", textAlign: "center" }}>
                Innovation
              </h2>
              <p1 style={{ textAlign: "center" }}>
                We develop new technologies and product designs to create an
                evolving platform with a wide range of uses.
              </p1>
            </Col>

            <Col className={style.visionText}>
              <img src={mail} width="110px" height="100px" alt="mail" />
              <h2 style={{ color: "#003f72", textAlign: "center" }}>
                Information
              </h2>
              <p1 style={{ textAlign: "center" }}>
                We monitor disease-specific markers to deliver data and insights
                to users.
              </p1>
            </Col>
            <Col className={style.visionText}>
              <img src={brain} width="105px" height="100px" alt="brain" />
              <h2 style={{ color: "#003f72", textAlign: "center" }}>
                Intelligence
              </h2>
              <p1 style={{ textAlign: "center" }}>
                We provide a personalized health management experience using
                AI-driven algorithms to adapt to individual user conditions.
              </p1>
            </Col>
          </Row>
        </div>
        <div class={style.background}>
          {/* <VisibilitySensor partialVisibility = {true} >
                    {({ isVisible }) => (
                        <Spring delay={100} to={{ opacity: isVisible ? 1 : .5 }}>
                        {({ opacity }) => (
                            
                            <div className={style.useCaseInfo} style = {{opacity}}> */}
          <div className={style.alcohol}>
            <h2 className={style.alcoholHeader}>Alcohol Use Disorders</h2>
            <p className={style.alcoholText}>
              Sense4me is developing a digital platform and a predictor of
              relapse for alcohol use disorders. There are 14.8 M people with
              alcohol use disorder in the US alone. Alcohol use disorder is a
              major cause of disability, and premature mortality. For the most
              common consumed substances, nicotine and alcohol, the probability
              of relapse within the first year after treatment is 80-95%. This
              reveals a lack of efficacy of current clinical treatments
              including the ability to predict and treat “cravings” a key
              feature of substance use disorders. Our vision is to become a
              leading provider of personalized health analytics. We aim to
              transform the standard of care and empower patients to actively
              participate in their treatment.
            </p>
            <div class={style.btnDiv}>
              {/* <Link to="/products/mental-health" class={style.learnMore} >Learn More</Link> */}
              <Link to="/mentalhealth" class={style.learnMore}>
                Learn More
              </Link>
            </div>
          </div>
          {/* )}
                        </Spring>
                    )} 
                     </VisibilitySensor>

                    <VisibilitySensor>
                    {({ isVisible }) => (
                        <Spring delay={100} to={{ opacity: isVisible ? 1 : 0.5 }}>
                        {({ opacity }) => (
                            
                            <div className={style.useCaseInfo} style = {{opacity}}>

                                <h2>Infectious Disease</h2>
                            

                                <p style={{fontSize: "20px", color: "white"}}>A wearable patch that helps doctors remotely track immune response in COVID-19 patients and other infectious diseases</p>
                            
                                <div class={style.btnDiv}>
                                    <Link to="/products/covid" class={style.learnMore}>Learn More</Link>
                                </div>
                            </div>
                        )}
                        </Spring>
                    )}
                    </VisibilitySensor>


                    <VisibilitySensor>
                    {({ isVisible }) => (
                        <Spring delay={100} to={{ opacity: isVisible ? 1 : 0.5 }}>
                        {({ opacity }) => (
                            
                            <div className={style.useCaseInfo} style = {{opacity}}>

                                <h2>Chronic Illness</h2>
                                <p1 style={{fontSize: "20px"}}>Remote monitoring of chronic conditions to predict flare-ups and assist in management of autoimmune conditions</p1>
                                <div class={style.btnDiv}>
                                    <Link to="/products/chronic-illness" class={style.learnMore}>Learn More</Link>
                                </div>

                            </div>
                        )}
                        </Spring>
                    )}
                    </VisibilitySensor> */}
        </div>
        <Footer />
      </>
    );
  }
}

export default Home;

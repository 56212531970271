//User Functions sends requests to flask with user login and registration data
//Some code inclued used from https://github.com/ArjunAranetaCodes/MoreCodes-Youtube/blob/master/react-flask-mysql-login-reg/src/components/UserFunctions.js

import axios from 'axios'

export const register = newUser => {
  
  return axios
    .post('api/register', {
      firstName: newUser.first_name,
      lastName: newUser.last_name,
      userName: newUser.username,
      email: newUser.email,
      password: newUser.password
    })
    .then(response => {
      //console.log(response)
    })
}

export const login = user => {
  
  return axios
    .post('api/login', {
      email: user.email,
      password: user.password
    })
    .then(response => {
      //console.log(response)
      localStorage.setItem('usertoken', response.data)
      return response.data
    })
    .catch(err => {
      console.log(err)
    })
}

export const getProfile = user => {
  return axios
    .get('api/profile', {
      //headers: { Authorization: ` ${this.getToken()}` }
    })
    .then(response => {
      //console.log(response)
      return response.data
    })
    .catch(err => {
      console.log(err)
    })
}
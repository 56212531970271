//some code for hamburger menu and scalable navbar from https://github.com/dieudonneAwa/responsive-navbar/blob/master/src/components/Nav/RightNav.jsx
import React, {Component} from 'react';
import styled from 'styled-components';
import { Nav, Navbar, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { withRouter } from 'react-router'

const Style = styled.div`
@media (max-width: 768px) {
    
    display:none;
    
}
.navStyle{
    
    a, .navbar-nav, .nav-link{
        color: black;
        font-size: 15px;
        font-weight: bold;
        &: hover {
            color: grey;
            text-decoration: none;
        }
    }  
  
}
`

const Ul = styled.ul`

@media (min-width: 768px) {
    
    display:none;
    
}
@media (max-width: 768px) {
    flex-flow: column nowrap;
    background-color: gray;
    color: black;
    position: fixed;
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};
    top: 0;
    right: 0;
    height: 100vh;
    width: 200px;
    padding-top: 3.5rem;
    transition: transform 0.3s ease-in-out;

    
}
  
`;




class AccountRightNav extends Component {
    
     
    logOut(e) {
        e.preventDefault()
        localStorage.removeItem('usertoken')
        this.props.history.push(`/`)
      }

      render() {
        const logOutLink = (
            <Nav className="navStyle">
            <Nav.Link >
                <Link onClick={this.logOut.bind(this)} > Logout </Link>
            </Nav.Link> 
        </Nav>
            )
        
  return (
      <>
    <Ul >
        
        <Nav.Link>
            <Link to="/dashboard"> Dashboard </Link>
        </Nav.Link>
        
        <Nav.Link>
            <Link to="/activity"> Activity </Link>
        </Nav.Link>
        
        <Nav.Link>
            <Link to="/settings"> Settings </Link>
        </Nav.Link>
        
        {/* <Nav.Link >
            <Link to="/dashboard"> My account </Link>
        </Nav.Link>     */}
        
    </Ul>
    <Nav className='mr-auto'>
        <Style>
            <Nav className="navStyle">
                <Nav.Link>
                    <Link to="/dashboard"> Dashboard </Link>
                </Nav.Link>
                
                <Nav.Link>
                    <Link to="/activity"> Activity </Link>
                </Nav.Link>
                
                <Nav.Link>
                    <Link to="/settings"> Settings </Link>
                </Nav.Link>
            </Nav>
        </Style>
    </Nav>
    <Style>
    {logOutLink}
       
    </Style>
    </>
  )
      }
}

export default withRouter(AccountRightNav)